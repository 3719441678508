<template>
	<div class="page container">
		<div class="activity" v-if="activity && currentGroup && currentGroup.type === 'quiz'">

			<div class="content">
				<div class="main">
					<div class="activity__content">
						<div class="activity__content--block">
							<span class="question" v-html="activity.question" />
							<div
								class="activity__content--block-diagrams"
								v-if="questionDiagrams.length"
							>
								<div v-for="diagram in questionDiagrams" :key="diagram.uuid + '-1'">
									<V2BiddingTable v-if="diagram.type === 'BiddingTable'" :diagram="diagram" />
									<V2Hand v-else-if="diagram.type === 'Hand'" :diagram="diagram" position="north" />
									<V2DealMatrix v-else-if="diagram.type === 'DealMatrix'" :diagram="diagram" />
								</div>
							</div>
						</div>
					</div>

					<p v-if="confirmed && !isCorrect && !showExplanation" class="animate-ping tryAgainText">
						Try again!
					</p>

					<div class="activity__content">
						<div class="activity__content--block">
							<div class="diagram-info">
								<icon icon="v2-info" colour="transparent" />
								{{ answerAdditionalText }}
							</div>
							<div class="activity__content--block-diagrams">
								<div
									v-for="diagram in answerDiagrams"
									:key="diagram.uuid + '-2'"
									class="diagram"
								>
									<!-- class:	{..., missed: confirmed && checkMissed(diagram.uuid) }	 -->
									<V2Button
										v-if="diagram.type !== 'BiddingInterface'"
										class="diagram__btn"
										:disabled="confirmed && isCorrect"
										:class="{
											active: checkAnswer(diagram.uuid),
											correct: confirmed && checkCorrect(diagram.uuid),
											incorrect: confirmed && checkIncorrect(diagram.uuid),
											text: diagram.type === 'TextAnswer'
										}"
										variant="text"
										@click="selectAnswer(diagram.uuid)"
									>
										<span
											v-if="diagram.type === 'TextAnswer'"
											v-html="diagram.config.text"
										/>
										<V2BiddingTable v-else-if="diagram.type === 'BiddingTable'" :diagram="diagram" />
										<V2Hand v-else-if="diagram.type === 'Hand'" :diagram="diagram" position="north" />
										<V2DealMatrix v-else-if="diagram.type === 'DealMatrix'" :diagram="diagram" />

										<icon
											v-if="confirmed && (checkCorrect(diagram.uuid) || checkIncorrect(diagram.uuid))"
											class="bid-icon"
											:icon="checkCorrect(diagram.uuid) ? 'v2-correct-answer' : 'v2-incorrect-answer'"
											colour="transparent"
										/>
									</V2Button>
									<div v-else>
										<V2BiddingInterface
											:diagram="diagram"
											:selectedBid="getSelectedBidValue(diagram.uuid)"
											@select-bid="selectBid($event, diagram.uuid)"
											:isConfirmed="confirmed"
											:disabled="confirmed && isCorrect"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="showHint && !showExplanation" class="activity__explanation">
						<div class="activity__explanation--text">
							<span v-html="activity.hint" />
						</div>
					</div>
					<div v-if="showExplanation" class="activity__explanation">
						<div class="activity__explanation--text">
							<span v-html="activity.explanation" />
						</div>
					</div>
				</div>
				<div class="side">
					<div class="activity__block">
						<div class="activity__block--title">
							<icon icon="v2-book-round" colour="transparent" />
							Quiz | {{ currentActivity.title }}
						</div>

						<div class="activity__block--controls">
							<V2Button
								v-if="answersAmount !== 1 && activity.mustChooseAllCorrectAnswers && !showExplanation && !(confirmed && isCorrect)"
								variant="red"
								:disabled="isConfirmBtnDisabled"
								@click="confirmAnswer"
							>
								Confirm
							</V2Button>
							<V2Button v-if="!showExplanation" variant="outline" @click="showAnswer">
								Show me the answer
							</V2Button>

							<V2Button v-if="activity.hint && !showHint" variant="filled" @click="onShowHint">
								Show me a hint
							</V2Button>
						</div>
					</div>

					<div
						v-if="confirmed && !isShowAnswer"
						class="answer-status"
						:class="[
							isCorrect ? 'correct' : 'incorrect',
						]"
					>
						<icon :icon="isCorrect ? 'v2-correct-answer' : 'v2-incorrect-answer'" colour="transparent" />
						{{ isCorrect ? 'Well done, that’s correct!' : 'Oh no, try again!' }}
					</div>

					<div class="activity__block">
						<div class="activity__block--title">
							<icon icon="v2-book-round" colour="transparent" />
							Progress
						</div>

						<div class="activity__block--controls row">
							<V2Button variant="outline" :to="prevActivity" :disabled="!prevActivity">
								Previous
							</V2Button>
							<V2Button
								v-if="nextActivity"
								variant="filled"
								:to="nextActivity"
								:class="{'animate-ping2': (confirmed && nextActivity && isCorrect && !isShowAnswer || showExplanation) && nextActivity }">
								Next
							</V2Button>
							<V2Button
								v-else
								variant="filled"
								:to="`/teaching/courses/${getCourseId}/lessons/${getLessonId}`"
								:class="{'animate-ping2': (confirmed && !nextActivity && isCorrect && !isShowAnswer || showExplanation) && !nextActivity }">
								Finish
							</V2Button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else-if="activity && currentGroup && currentGroup.type === 'titlePage'" class="media media-title">
			<TitlePage />
		</div>

		<div v-else-if="activity && currentGroup" class="media">
			<Media />
		</div>
	</div>
</template>

<script>
	import Icon from '@/components/ui/Icon';
	import routeParams from '@/mixins/routeParams';
	import V2Button from '@/components/v2/ui/V2Button';
	import V2BiddingTable from '@/components/v2/diagrams/V2BiddingTable';
	import V2Hand from '@/components/v2/diagrams/V2Hand';
	import V2DealMatrix from '@/components/v2/diagrams/V2DealMatrix';
	import V2BiddingInterface from '@/components/v2/diagrams/V2BiddingInterface';
	import Media           from '@/components/activities/view/activityTypes/Media';
	import TitlePage           from '@/components/activities/view/activityTypes/TitlePage';
	import api from '@/services/api';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Activity'
		},

		components: {
			Icon,
			V2Button,
			V2BiddingTable,
			V2Hand,
			V2DealMatrix,
			V2BiddingInterface,
			Media,
			TitlePage
		},

		mixins: [routeParams],

		data () {
			return {
				activity: null,
				selectedAnswer: [],
				biddingInterfaceAnswers: [],
				confirmed: false,
				showHint: false,
				showExplanation: false,
				isCorrect: null,
				isShowAnswer: false,

				isActive: false,

				confirmedWrongAnswer: false
			};
		},

		computed: {

			answerAdditionalText () {
        if (this.activity.mustChooseAllCorrectAnswers) {
          return 'Choose multiple answers, then Confirm';
        }

        if (this.biddingDiagrams.length) {
          return 'Choose a bid';
        }

        return 'Choose an answer';
			},

			answersAmount () {
				return this.biddingInterfaceAnswers.length + this.correctAnswer.length;
			},

			isConfirmBtnDisabled () {
				const noDefaultAnswers = !this.selectedAnswer.length;
				const noBidAnswers = !this.biddingInterfaceAnswers.filter((item) => item.selectedValue).length;
				return noDefaultAnswers && noBidAnswers && !this.confirmed;
			},

			getLessonIsLive () {
				return this.$store.getters['lesson/getLessonIsLive'];
			},

			getCourseTitle () {
				return this.$store.getters['lesson/getCourseTitle'];
			},

			getLessonTitle () {
				return this.$store.getters['lesson/getLessonTitle'];
			},

			getActivityTitle () {
				return this.$store.getters['lesson/getActivityTitle'];
			},

			getBreadcrumbs () {
				if (this.getLessonIsLive) {
					return [];
				}
				return [
					(this.getContext === 'admin' ?
						{
							path: '/admin',
							text: 'Admin'
						} : false
					),
					(this.getContext === 'teaching' ?
						{
							path: '/teaching',
							text: 'Teaching'
						} : false
					),
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					{
						path: this.getCourseRoute,
						text: this.getCourseTitle ? this.getCourseTitle : 'Course'
					},
					{
						path: this.getLessonRoute,
						text: this.getLessonTitle ? this.getLessonTitle : 'Lesson'
					},
					(!this.isCreate ?
						{
							path: this.getActivityRoute,
							text: this.getActivityTitle
						} : false
					)
				].filter(Boolean);
			},

			prevActivity () {
				const activityIdx = this.activities.findIndex(({ id }) => id === this.getActivityId);

				const nextId = this.activities[activityIdx - 1]?.id;

        if (!nextId) {
          return '';
        }

				return { params: { activityId: nextId } };
			},

			nextActivity () {
				const activityIdx = this.activities.findIndex(({ id }) => id === this.getActivityId);

				const nextId = this.activities[activityIdx + 1]?.id;

        if (!nextId) {
          return '';
        }

				return { params: { activityId: nextId } };
			},

			correctAnswer () {
				return this.answerDiagrams.filter(({ config }) => config.isCorrect).map(({ uuid }) => uuid);
			},

			questionDiagrams () {
				return this.activity.diagrams.filter(
					({ context }) => context === 'question'
				);
			},

			answerDiagrams () {
				return this.activity.diagrams.filter(
					({ context }) => context === 'answer'
				);
			},

			biddingDiagrams () {
        if (!this.activity.diagrams) {
          return [];
        }
				return this.activity.diagrams.filter(
					(item) => item.context === 'answer' && item.type === 'BiddingInterface'
				);
			},

			lesson () {
				return this.$store.state.lesson.v2Lesson;
			},

			activities () {
        if (!this.lesson) {
          return [];
        }
        return this.lesson.activities;
			},

			activityList () {
				return this.activities.reduce((prev, curr) => {
					const prevIdx = prev.length;

        if (curr.type === 'quiz') {
          if (prevIdx === 0 || prev[prevIdx - 1].type !== 'quiz') {
            prev.push({
              title: 'Quiz',
              type: 'quiz',
              activityGroupNumb: prev.length + 1,
              item: [{ ...curr, activityGroupItemNumb: 1 }]
            });
          } else {
            prev[prevIdx - 1].item.push({
              ...curr,
              activityGroupItemNumb: prev[prevIdx - 1].item.length + 1
            });
          }
        }

        if (curr.type === 'video') {
          prev.push({
            title: 'Video',
            type: 'video',
            activityGroupNumb: prev.length + 1,
            item: [curr]
          });
        }

        if (curr.type === 'embeddedPresentation') {
          prev.push({
            title: 'Presentation',
            type: 'embeddedPresentation',
            activityGroupNumb: prev.length + 1,
            item: [curr]
          });
        }

        if (curr.type === 'titlePage') {
          prev.push({
            title: 'Intertitle',
            type: 'titlePage',
            activityGroupNumb: prev.length + 1,
            item: [curr]
          });
        }

					return prev;
				}, []);
			},

			currentGroup () {
				const mappedItemIdIdx = this.activityList
					.map((group) => {
						return group.item.map(({ id }) => id);
					})
					.findIndex((idGroup) => idGroup.includes(this.getActivityId));

        if (mappedItemIdIdx === -1) {
          return null;
        }

				return this.activityList[mappedItemIdIdx];
			},

			currentActivity () {
        if (!this.currentGroup) {
          return null;
        }
        return this.currentGroup.item.find(({ id }) => id === this.getActivityId);
			}
		},

		async created () {
      if (
        !this.lesson ||
        this.lesson.id !== this.getLessonId ||
        !this.lesson.activities.find(({ id }) => id === this.getActivityId)
      ) {
        this.setLesson();
      }

      await this.setActivity();

			const activity = await api.teaching.getActivityByIdAndLessonId({
				lessonId: this.getLessonId,
				activityId: this.getActivityId
			});

      this.$store.commit('lesson/setActivity', {
        activity
      });

			this.activity = activity.config;

      if (this.biddingDiagrams.length) {
        this.setBiddingInterfaceAnswers();
      }

      this.$store.commit('ui/setLoadingIsHidden');
		},

		methods: {
			setBiddingInterfaceAnswers () {
				this.biddingInterfaceAnswers = this.biddingDiagrams.map((item) => {
					return {
						id: item.uuid,
						answerValue: item.config.answerValue,
						selectedValue: null
					};
				});

			},

			async setActivity () {
				await this.$store.dispatch('lesson/setActivity', {
					context: this.getContext,
					courseId: this.getCourseId,
					lessonId: this.getLessonId,
					activityId: this.getActivityId
				});
				this.$store.commit('ui/addTourSteps', {
					tourSteps: [
						this.getTourStep
					].filter(Boolean)
				});
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
			},

			selectBid (bid, uuid) {
        if (this.activity.mustChooseAllCorrectAnswers && this.confirmed) {
          this.confirmed = false;
        }

				this.biddingInterfaceAnswers = this.biddingInterfaceAnswers.map((item) => {
          if (item.id === uuid) {

            const selectedValue = item.selectedValue?.bid === bid.bid ? null : bid;
            return {
              ...item,
              selectedValue
            };
          }
          return item;
				});

        if (this.answersAmount === 1 || !this.activity.mustChooseAllCorrectAnswers) {
          this.confirmAnswer();
        }
			},

			getSelectedBidValue (uuid) {
				return this.biddingInterfaceAnswers.find(item => item.id === uuid).selectedValue;
			},

			checkCorrect (id) {
				return this.selectedAnswer.includes(id) && this.correctAnswer.includes(id);
			},

			checkIncorrect (id) {
				return this.selectedAnswer.includes(id) && !this.correctAnswer.includes(id);
			},

			checkMissed (id) {
				return !this.selectedAnswer.includes(id) && this.correctAnswer.includes(id);
			},

			checkAnswer (id) {
				return this.selectedAnswer.includes(id);
			},

			selectAnswer (id) {
        if (this.activity.mustChooseAllCorrectAnswers) {
          if (this.selectedAnswer.includes(id)) {
            this.selectedAnswer = this.selectedAnswer.filter((ids) => ids !== id);
          } else {
            this.selectedAnswer.push(id);
          }
        } else {
          if (this.selectedAnswer[0] === id) {
            this.selectedAnswer = [];
          } else {
            this.selectedAnswer = [id];
          }
        }

        if (this.answersAmount === 1 || !this.activity.mustChooseAllCorrectAnswers) {
          this.confirmAnswer();
        }

			},

			async confirmAnswer () {

				const correctAnswers = [...this.correctAnswer];
				const selectedAnswers = [...this.selectedAnswer];

				this.selectedAnswer.forEach(answer => {
					const correctIdx = correctAnswers.findIndex((uuid) => uuid === answer);

					const selectedIdx = selectedAnswers.findIndex((uuid) => uuid === answer);

          if (correctIdx !== -1) {
            correctAnswers.splice(correctIdx, 1);
            selectedAnswers.splice(selectedIdx, 1);
          }
				});

				const isBidCorrectAnswersAll = this.biddingInterfaceAnswers.every(item => item.selectedValue?.bid === item.answerValue);
				const isBidCorrectAnswer = this.biddingInterfaceAnswers.some(item => item.selectedValue?.bid === item.answerValue);

				const isDefaultCorrentAnswers = this.correctAnswer.length ? (!correctAnswers.length && !selectedAnswers.length) : true;

				this.confirmed = true;

        if (this.activity.mustChooseAllCorrectAnswers) {
          this.isCorrect = isDefaultCorrentAnswers && isBidCorrectAnswersAll;
        } else {
          this.isCorrect = isBidCorrectAnswer || correctAnswers.length !== this.correctAnswer.length;
        }

        if (!this.isCorrect) {
          this.onShowHint();
          this.confirmedWrongAnswer = true;
        } else {
          if (!this.confirmedWrongAnswer) {
            this.showExplanation = true;
            setTimeout(() => {
              window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
            }, 200);
          }

        }

			},

			onShowHint () {
				this.showHint = true;
				setTimeout(() => {
					window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
				}, 200);
			},

			async restart () {
				this.selectedAnswer = [];
				this.confirmed = false;
				this.showExplanation = false;
				this.showHint = false;

				this.isCorrect = null;
				this.setBiddingInterfaceAnswers();
			},

			createBid (bidAnswer) {
				if (bidAnswer === 'X') {
          return {
            bid: bidAnswer,
            isValid: true,
            type: 'double'
          };
        }

        if (bidAnswer === 'XX') {
          return {
            bid: bidAnswer,
            isValid: true,
            type: 'redouble'
          };
        }

        if (bidAnswer === 'P') {
          return {
            bid: bidAnswer,
            isValid: true,
            type: 'pass'
          };
        }

				return {
					bid: bidAnswer,
					isValid: true,
					suit: bidAnswer.slice(1),
					type: 'contract',
					value: bidAnswer.slice(0, 1)
				};
			},

			async showAnswer () {
				this.selectedAnswer = [...this.correctAnswer];
				this.biddingInterfaceAnswers = this.biddingInterfaceAnswers.map((item) => {
					return {
						...item,
						selectedValue: this.createBid(item.answerValue)
					};
				});

				this.confirmed = true;
				this.showExplanation = true;
				this.isCorrect = false;
				this.isShowAnswer = true;

				setTimeout(() => {
					window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
				}, 200);

			},

			async setLesson () {
				const lesson = await api.teaching.getLessonById({
					lessonId: this.getLessonId
				});

        if (!lesson) {
          this.$store.commit('ui/showError');
          return false;
        }

				this.$store.commit('lesson/v2SetLesson', lesson);

				this.$store.commit('ui/setLoadingIsHidden');
			},

			openActivity (id) {
				this.$router.replace({ params: { activityId: id } });
			}
		}
	};
</script>

<style lang="scss" scoped>

.bid-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 2;
}

.tryAgainText {
  margin-bottom: 16px;
  @include font();
  text-align: center;
}

.animate-ping {
  animation: pulse 1s infinite alternate;
  box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.7);
}

@keyframes pulse {
  0% {
    color: rgba(255, 215, 0, 1);
  }

  100% {
    color: $c-dark-navy;
  }
}

.animate-ping2 {
  animation: pulse2 .9s ease infinite alternate;
  box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.7);
}

@keyframes pulse2 {
  0% {
    color: $c-white;
  }

  100% {
    color: rgba(255, 215, 0, 1);
  }
}

.media {
  margin-top: 24px;
}

.media-title {
  height: 100%;
  display: flex;
  flex: 1;

  [data-component="title-page"] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    [data-component="activity-buttons"] {

    }
  }
}

.answer-status {
  display: flex;
  align-items: center;
  justify-content: center;
  @include font();
  gap: 12px;
  padding: 16px;
  margin-bottom: 18px;

  &.correct {
    background: $c-green-l2;
    color: $c-darkest-navy;
    border: 1px solid $c-green-l2;
  }

  &.incorrect {
    background: $c-red-l2;
    color: $c-darkest-navy;
    border: 1px solid $c-red-l2;
  }
}

.activity {
  display: flex;
  flex-direction: column;
  position: relative;

  .side {
    position: sticky;
    top: 20px;
    height: fit-content;
  }

  @media (max-width: 1279px) {
    .content {
      display: flex;
      flex-direction: column;

      .main, .side {
        width: 100%;
      }
    }
  }

  &__title {
    margin-top: 24px;
    @include font(28px, $c-darkest-navy, 500, 36px);
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &:first-child {
      margin-bottom: 18px;
    }

    &--title {
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 7.5px 0;
      @include font(18px, $c-darkest-navy, 700, 23px);
    }

    &--controls {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &.row {
        flex-direction: row;
      }

      &-btn {
        width: 100%;
        border-radius: 4px;
        @include font(16px, $c-dark-navy, 500, 20px);
        padding: 8px 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.red {
          background: $c-red;
          color: $c-white-l;
          border: 1px solid $c-red;

          &.disabled {
            opacity: 0.75;
          }
        }

        &.outline {
          background: $c-bg-white;
          color: $c-dark-navy;
          border: 1px solid $c-dark-navy;
        }

        &.filled {
          background: $c-dark-navy;
          color: $c-white-l;
          border: 1px solid $c-dark-navy;
        }

        &.correct {
          background: $c-green-l2;
          color: $c-darkest-navy;
          border: 1px solid $c-green-l2;
        }

        &.incorrect {
          background: $c-red-l2;
          color: $c-darkest-navy;
          border: 1px solid $c-red-l2;
        }
      }
    }

    &--groups {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .group-item {
        display: flex;
        flex-direction: column;
        padding: 12px;
        gap: 12px;
        border-radius: 4px;
        border: 1px solid $c-light-navy;

        &__head {
          display: flex;
          justify-content: space-between;
          gap: 8px;
          align-items: center;
          @include font(18px, $c-dark-navy, 400, 23px);

          &--title {
            display: flex;
            gap: 8px;
          }

          .controll-icon {
            cursor: pointer;
          }

          .inactive {
            transform: rotate(0deg);
          }
        }

        &__body {
          display: flex;
          flex-direction: column;

          &-item {
            width: 100%;
            padding: 6px 8px;
            border-radius: 2px;
            @include font(16px, $c-dark-navy, 600, 20px);
            border: 1px solid transparent;
            background: transparent;
            justify-content: flex-start;

            span {
              margin-left: 4px;
            }

            &.active {
              background: $c-medium-navy;
              color: $c-white-l;
              font-weight: 700;

              &:hover {
                background: $c-medium-navy;
                border-color: $c-medium-navy;
              }
            }
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;

    ::v-deep span {
      @include rich-text-content;
      @include rich-text-suits;
    }

    &--title {
      margin-bottom: 16px;
      @include font(24px, $c-darkest-navy, 500, 31px);
    }

    &--block {
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 24px;
      background: $c-white-l;
      border-radius: 4px;
      align-items: center;
      @include font(18px, $c-dark-navy, 500, 23px);

      .question {
        @include font(18px, $c-dark-navy, 500, 23px);
      }

      &-diagrams {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .diagram {
          &__btn {
            border: 1px solid $c-dark-navy;
            border-radius: 4px;
            box-sizing: border-box;
            @include font(16px, $c-dark-navy, 500, 20px);
            position: relative;

            &.text {
              padding: 8px 20px;
            }

            &.active {
              border: 2px solid $c-dark-navy;
              background: $c-gray-l;
            }

            &.correct {
              background: $c-green-l2;
              border: 2px solid $c-green-l;
            }

            &.incorrect {
              background: $c-red-l2;
              border: 2px solid $c-red-l;
            }

            &.missed {
              background: $c-yellow-l;
              border: 2px solid $c-yellow;
            }

            &.disabled {
              cursor: not-allowed;
              color: $c-black-l;
            }
          }
        }
      }

      .diagram-info {
        display: flex;
        align-items: center;
        gap: 6px;
        @include font(14px, $c-dark-navy, 400, 18px);
      }
    }

    img {
      width: 100%;
    }
  }

  &__explanation {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    border: 1px solid $c-gray-d2;
    border-radius: 4px;
    gap: 6px;
    margin-bottom: 16px;

    &--title {
      @include font(16px, $c-darkest-navy, 700, 20px);
    }

    &--text {
      @include font(16px, $c-darkest-navy, 400, 20px);

      ::v-deep span {
        @include rich-text-content;
        @include rich-text-suits;
      }
    }

  }
}
</style>
